<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="6"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <div class="col col-lg-auto">
              <b-button
                variant="success"
                @click="ExportExcel"
              > 
                <feather-icon icon="DownloadIcon" /> {{ $t('globalActions.excel') }}
              </b-button>
          </div>
          <div class="col col-lg-auto">
              <b-button
                variant="primary"
                :to="{ name: 'apps-master-data-tax-add'}"
              >
                <feather-icon icon="PlusIcon" /> {{ $t('apps.masterDataTax.actions.create') }}
              </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: code -->
      <template #cell(name)="data">
        <b-link
          :to="{ name: 'apps-master-data-tax-detail', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          {{ data.item.name }}
        </b-link>
      </template>

      <!-- Column: rate -->
      <template #cell(rate)="data">
        {{ data.item.rate }}%
      </template>

      <!-- Column: sell_account_id -->
      <template #cell(sell_account_id)="data">
        {{ `${data.item.sell_account.account_number} - ${data.item.sell_account.account_name}` }}
      </template>

      <!-- Column: buy_account_id -->
      <template #cell(buy_account_id)="data">
        {{ `${data.item.buy_account.account_number} - ${data.item.buy_account.account_name}` }}
      </template>

      <!-- Column: audit -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

      <!-- Column: Account Type -->
      <!-- <template #cell(accountTypes)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.value.type_name }}
        </span>
      </template> -->
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner
} from 'bootstrap-vue'

// import useList from './useList'
import useListTable from '@/comp-functions/useListTable'
import useLocalization from '@/comp-functions/utils/useLocalization'
import { unformatNumber } from '@/utils/formatter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner
  },
  setup () {
    // Table Handlers
    const { $t } = useLocalization()
    const tableColumns = [
      { key: 'name', label: 'Name Tax', sortable: true },
      { key: 'rate', label: 'Rate' },
      { key: 'sell_account_id', label: 'Account sell' },
      { key: 'buy_account_id', label: 'Account Buy' },
      { key: 'audit', label: $t('globalSingular.audit') }
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'name',
      'rate',
      'sell_account_id',
      'buy_account_id'
    ]

    return {
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'master/tax'
      })
    }
  },
  methods: {
    ExportExcel () {
       import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists
        for (let index = 0; index < list.length; index++) {
          list[index].audit = list[index].updated_process + ' By ' + list[index].getUser.user_name + ' Date ' + list[index].updated_at
          list[index].sell_account_id = list[index].sell_account.account_number + '-' + list[index].sell_account.account_name
          list[index].buy_account_id = list[index].buy_account.account_number + '-' + list[index].buy_account.account_name
        }

        const date = new Date();
        
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
      
        let currentDate = `${day}-${month}-${year}`;

        const headerTitle = this.tableColumns.map(column => column.label)

        const headerVal = this.tableColumns.map(column => column.key)

        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            ['Master Data', '', '', '', '', '', ''],
            ['Tax', '', '', '', '', '', ''],
            [currentDate, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Master data Tax`,
          autoWidth: true,
          bookType: 'xlsx'
        })

      })

    },
        formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>
